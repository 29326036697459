@font-face {
    font-family: "iconfont";
    src: url('/cache-buster-1743572567060/static/build/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/cache-buster-1743572567060/static/build/icons/font/iconfont.woff') format('woff'),
    url('/cache-buster-1743572567060/static/build/icons/font/iconfont.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

.icon {
    display: inline-block;
    font-family: "iconfont";
    speak: none;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.icon-lg {
    font-size: 1.3333333333333333em;
    line-height: 0.75em;
    vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
    width: 1.2857142857142858em;
    text-align: center;
}

.icon-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
}
.icon-ul > li {
    position: relative;
}
.icon-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: 0.14285714em;
    text-align: center;
}
.icon-li.icon-lg {
    left: -1.85714286em;
}

.icon-rotate-90 {
    transform: rotate(90deg);
}
.icon-rotate-180 {
    transform: rotate(180deg);
}
.icon-rotate-270 {
    transform: rotate(270deg);
}
.icon-flip-horizontal {
    transform: scale(-1, 1);
}
.icon-flip-vertical {
    transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-addthis:before { content: "\EA01" }
.icon-archive:before { content: "\EA02" }
.icon-arrow-back:before { content: "\EA03" }
.icon-arrow-right:before { content: "\EA04" }
.icon-availability:before { content: "\EA05" }
.icon-back:before { content: "\EA06" }
.icon-bar-code:before { content: "\EA07" }
.icon-belege:before { content: "\EA08" }
.icon-blaetterkatalog:before { content: "\EA09" }
.icon-bulletpoint:before { content: "\EA0A" }
.icon-camera:before { content: "\EA0B" }
.icon-cart:before { content: "\EA0C" }
.icon-checkbox:before { content: "\EA0D" }
.icon-close:before { content: "\EA0E" }
.icon-comparison-numbers:before { content: "\EA0F" }
.icon-consultation:before { content: "\EA10" }
.icon-delete:before { content: "\EA11" }
.icon-delivery:before { content: "\EA12" }
.icon-dokumente:before { content: "\EA13" }
.icon-edit:before { content: "\EA14" }
.icon-elements:before { content: "\EA15" }
.icon-error:before { content: "\EA16" }
.icon-expand:before { content: "\EA17" }
.icon-eye-crossed-out:before { content: "\EA18" }
.icon-eye:before { content: "\EA19" }
.icon-facebook:before { content: "\EA1A" }
.icon-fax:before { content: "\EA1B" }
.icon-feedback:before { content: "\EA1C" }
.icon-formulare:before { content: "\EA1D" }
.icon-google-plus:before { content: "\EA1E" }
.icon-heart-filled:before { content: "\EA1F" }
.icon-heart-outline:before { content: "\EA20" }
.icon-home:before { content: "\EA21" }
.icon-kalender:before { content: "\EA22" }
.icon-line:before { content: "\EA23" }
.icon-linkedin:before { content: "\EA24" }
.icon-list-checked:before { content: "\EA25" }
.icon-list:before { content: "\EA26" }
.icon-lkw-delivery:before { content: "\EA27" }
.icon-lkw-pickup:before { content: "\EA28" }
.icon-lkw:before { content: "\EA29" }
.icon-magazine:before { content: "\EA2A" }
.icon-mail:before { content: "\EA2B" }
.icon-menu:before { content: "\EA2C" }
.icon-minus:before { content: "\EA2D" }
.icon-newsletter:before { content: "\EA2E" }
.icon-offene-positionen:before { content: "\EA2F" }
.icon-order:before { content: "\EA30" }
.icon-passwort:before { content: "\EA31" }
.icon-phone:before { content: "\EA32" }
.icon-pinterest:before { content: "\EA33" }
.icon-play:before { content: "\EA34" }
.icon-plus:before { content: "\EA35" }
.icon-poi:before { content: "\EA36" }
.icon-products:before { content: "\EA37" }
.icon-produktanfrage:before { content: "\EA38" }
.icon-reduce-costs:before { content: "\EA39" }
.icon-replacement:before { content: "\EA3A" }
.icon-ruecksendungen:before { content: "\EA3B" }
.icon-sales-support:before { content: "\EA3C" }
.icon-save:before { content: "\EA3D" }
.icon-search:before { content: "\EA3E" }
.icon-singleselect-empty:before { content: "\EA3F" }
.icon-singleselect:before { content: "\EA40" }
.icon-smiley:before { content: "\EA41" }
.icon-stammdaten:before { content: "\EA42" }
.icon-status:before { content: "\EA43" }
.icon-stock:before { content: "\EA44" }
.icon-tick:before { content: "\EA45" }
.icon-truck:before { content: "\EA46" }
.icon-twitter:before { content: "\EA47" }
.icon-upload:before { content: "\EA48" }
.icon-user-hair:before { content: "\EA49" }
.icon-user-headset:before { content: "\EA4A" }
.icon-user:before { content: "\EA4B" }
.icon-whatsapp:before { content: "\EA4C" }


:root {
--icon-addthis: "\EA01";
    --icon-archive: "\EA02";
    --icon-arrow-back: "\EA03";
    --icon-arrow-right: "\EA04";
    --icon-availability: "\EA05";
    --icon-back: "\EA06";
    --icon-bar-code: "\EA07";
    --icon-belege: "\EA08";
    --icon-blaetterkatalog: "\EA09";
    --icon-bulletpoint: "\EA0A";
    --icon-camera: "\EA0B";
    --icon-cart: "\EA0C";
    --icon-checkbox: "\EA0D";
    --icon-close: "\EA0E";
    --icon-comparison-numbers: "\EA0F";
    --icon-consultation: "\EA10";
    --icon-delete: "\EA11";
    --icon-delivery: "\EA12";
    --icon-dokumente: "\EA13";
    --icon-edit: "\EA14";
    --icon-elements: "\EA15";
    --icon-error: "\EA16";
    --icon-expand: "\EA17";
    --icon-eye-crossed-out: "\EA18";
    --icon-eye: "\EA19";
    --icon-facebook: "\EA1A";
    --icon-fax: "\EA1B";
    --icon-feedback: "\EA1C";
    --icon-formulare: "\EA1D";
    --icon-google-plus: "\EA1E";
    --icon-heart-filled: "\EA1F";
    --icon-heart-outline: "\EA20";
    --icon-home: "\EA21";
    --icon-kalender: "\EA22";
    --icon-line: "\EA23";
    --icon-linkedin: "\EA24";
    --icon-list-checked: "\EA25";
    --icon-list: "\EA26";
    --icon-lkw-delivery: "\EA27";
    --icon-lkw-pickup: "\EA28";
    --icon-lkw: "\EA29";
    --icon-magazine: "\EA2A";
    --icon-mail: "\EA2B";
    --icon-menu: "\EA2C";
    --icon-minus: "\EA2D";
    --icon-newsletter: "\EA2E";
    --icon-offene-positionen: "\EA2F";
    --icon-order: "\EA30";
    --icon-passwort: "\EA31";
    --icon-phone: "\EA32";
    --icon-pinterest: "\EA33";
    --icon-play: "\EA34";
    --icon-plus: "\EA35";
    --icon-poi: "\EA36";
    --icon-products: "\EA37";
    --icon-produktanfrage: "\EA38";
    --icon-reduce-costs: "\EA39";
    --icon-replacement: "\EA3A";
    --icon-ruecksendungen: "\EA3B";
    --icon-sales-support: "\EA3C";
    --icon-save: "\EA3D";
    --icon-search: "\EA3E";
    --icon-singleselect-empty: "\EA3F";
    --icon-singleselect: "\EA40";
    --icon-smiley: "\EA41";
    --icon-stammdaten: "\EA42";
    --icon-status: "\EA43";
    --icon-stock: "\EA44";
    --icon-tick: "\EA45";
    --icon-truck: "\EA46";
    --icon-twitter: "\EA47";
    --icon-upload: "\EA48";
    --icon-user-hair: "\EA49";
    --icon-user-headset: "\EA4A";
    --icon-user: "\EA4B";
    --icon-whatsapp: "\EA4C";
    
}